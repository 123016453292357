<template>
  <div class="letter-templates">
    <loading v-if="vLoading" />
    <v-card class="cards pa-8">
      <v-row>
        <v-col> <h4 class="grey-color">قالب‌های نامه</h4></v-col>
        <v-col class="text-left">
          <div class="custom-input">
            <v-text-field
              class="float-end"
              v-model="search"
              outlined
              dense
              placeholder="نام قالب را جستجو کنید..."
              style="width: 330px"
            >
              <template #prepend-inner>
                <v-icon> $Magnify </v-icon>
              </template>
              <template v-if="search.length" #append>
                <v-icon @click="search = ''"> $Close </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="custom-vselect pt-1 d-flex flex-row">
            <span class="ps-3 pt-3" style="padding-top: 10px">نوع قالب</span>
            <v-select
              outlined
              dense
              :items="typeOptions"
              v-model="templateType"
              @change="currentPage = 1"
            ></v-select>
          </div>
        </v-col>
        <v-col class="text-left d-flex align-items-center justify-content-end">
          <v-btn
            @click="
              addTemplateDialog = true;
              newTemplate = { public: false, text: '' };
            "
            class="blue-mainBtn"
            height="40"
            ><v-icon class="white-icon pe-2">$Document2</v-icon> افزودن قالب
            جدید</v-btn
          >
        </v-col>
      </v-row>
      <v-card class="text-center pa-10 cards" v-if="!isLoaded">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <circle-4></circle-4>
          <h6 class="mt-6">در حال بارگذاری ...</h6>
        </div>
      </v-card>
      <div v-else>
        <v-row v-if="!computedTemplates.length && !search">
          <v-col
            class="text-center text--grey text--darken-1"
            style="font-size: 16px"
          >
            <div class="border-box">
              <v-icon class="no-template-icon">$NoTemplate</v-icon>
              <p class="dark-grey-color mt-4 mb-0">
                قالبی برای نمایش وجود ندارد.
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!computedTemplates.length && search">
          <v-col
            class="text-center text--grey text--darken-1"
            style="font-size: 16px"
          >
            <div class="border-box">
              <v-icon class="no-template-icon">$NoResult</v-icon>
              <p class="dark-grey-color mt-4">
                نتیجه‌ای برای "{{ search }}" یافت نشد.
              </p>
            </div>
          </v-col>
        </v-row>
        <div v-if="computedTemplates.length" class="mt-6">
          <v-col>
            <div class="custom-table">
              <b-table
                responsive
                show-empty
                stacked="sm"
                :current-page="currentPage"
                :per-page="perPage"
                :items="computedTemplates"
                :fields="fields"
                empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                empty-text="قالبی برای نمایش وجود ندارد"
                striped
                thead-class="headClass"
                tbody-class="bodyClass"
                :busy="isBusy"
              >
                <template v-slot:cell(index)="data">
                  <div
                    style="text-align: center; vertical-align: middle"
                    class="pt-lg-3 pt-0"
                  >
                    {{ data.index + 1 + perPage * (currentPage - 1) }}
                  </div>
                </template>
                <template v-slot:head()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.value }}
                  </div>
                </template>
                <template v-slot:cell(public)="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.value ? "عمومی" : "خصوصی" }}
                  </div>
                </template>
                <template v-slot:cell(type)="data">
                  <div
                    style="text-align: center; vertical-align: middle"
                    class="pt-lg-1 pt-0"
                  >
                    <div
                      v-if="data.value == 'خروجی'"
                      class="py-2 ps-6 pe-6 orange-badge mx-auto"
                      style="width: 100px"
                    >
                      <span>
                        {{ data.value }}
                      </span>
                    </div>
                    <div
                      v-if="data.value == 'سازمانی'"
                      class="py-2 ps-6 pe-6 blue-badge mx-auto"
                      style="width: 100px"
                    >
                      <span>
                        {{ data.value }}
                      </span>
                    </div>
                  </div>
                </template>

                <template v-slot:cell(operation)="data">
                  <div style="text-align: center; vertical-align: middle">
                    <v-btn
                      @click="
                        getTemplateInfo(data.item.id);
                        edited = false;
                      "
                      height="42"
                      width="100"
                      class="blue-mainBtn ms-2 me-2 pa-3"
                    >
                      <v-icon class="pe-1">$View</v-icon>
                      مشاهده</v-btn
                    >
                    <v-btn
                      @click="openDeleteDialog(data.item)"
                      height="42"
                      width="100"
                      class="red-mainBtn ms-2 me-2 pa-3"
                    >
                      <v-icon class="pe-1">$Trash</v-icon>
                      حذف</v-btn
                    >
                  </div>
                </template>
                <div slot="table-busy" class="text-center text-danger my-2">
                  <v-progress-circular indeterminate color="#00acc1">
                  </v-progress-circular>
                </div>
              </b-table>
              <div
                v-if="computedTemplates.length"
                class="d-flex flex-row justify-content-end blue-custom-pagination mt-8"
              >
                <v-btn @click="goToFirst()" class="navigation-btn"
                  ><v-icon>$ArrowDoubleRight</v-icon></v-btn
                >
                <v-pagination
                  v-model="currentPage"
                  :length="Math.ceil(computedTemplates.length / perPage)"
                  :total-visible="5"
                  prev-icon="$ArrowLeft"
                  next-icon="$ArrowRight"
                ></v-pagination>
                <v-btn @click="goToLast()" class="navigation-btn"
                  ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
                >
              </div>
            </div>
          </v-col>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="addTemplateDialog" width="700">
      <v-card class="modal-card">
        <v-row class="mt-1">
          <v-col>
            <h5 class="mb-4 dark-grey-color">ثبت قالب جدید</h5>
            <div class="custom-input">
              <div>
                <v-text-field
                  outlined
                  dense
                  height="40"
                  v-model="newTemplate.name"
                >
                  <template slot="label">
                    <div style="font-size: 14px">عنوان قالب</div>
                  </template>
                </v-text-field>
              </div>
            </div>
            <div class="blue-ckeditor mt-2">
              <div style="font-size: 14px">متن</div>
              <div class="mt-3">
                <vue-ckeditor v-model="newTemplate.text" :config="config" />
              </div>
            </div>
            <div class="switch-div d-flex" v-if="role == 'admin'">
              <div class="v-label theme--light mt-6 me-2">خصوصی</div>
              <v-switch v-model="newTemplate.public" label="عمومی"></v-switch>
            </div>
            <div class="radio-div mt-3">
              <span class="v-label theme--light"
                >کاربرد این قالب در چه نوع نامه‌ای است؟</span
              >
              <v-radio-group v-model="newTemplate.type">
                <v-radio value="outbox">
                  <template slot="label">
                    <div class="mt-2" style="font-size: 14px">خروجی</div>
                  </template>
                </v-radio>
                <v-radio value="internal">
                  <template slot="label">
                    <div class="mt-2" style="font-size: 14px">سازمانی</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <v-btn
              class="blue-mainBtn"
              @click="submitTemplate()"
              :disabled="
                !newTemplate.name || !newTemplate.text || !newTemplate.type
              "
              :loading="addBusy"
              >افزودن</v-btn
            >
            <v-btn
              class="red-borderedBtn float-end"
              @click="addTemplateDialog = false"
              >بازگشت</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="templateInfoDialog" width="600">
      <v-card class="modal-card ps-8 pe-8">
        <v-row class="mt-1">
          <v-col v-if="!edited">
            <v-row class="mb-2">
              <v-col>
                <h5 class="mb-4 dark-grey-color">{{ selected.name }}</h5>
              </v-col>
              <v-col class="d-flex justify-content-end">
                <div
                  class="pink-badge pa-2 text-center border-0 mb-1"
                  style="width: 90px"
                >
                  <span>{{
                    selected.type == "internal" ? "سازمانی" : "خروجی"
                  }}</span>
                </div>
                <div
                  class="type-badge pa-2 ms-2 text-center mb-1"
                  style="width: 90px"
                >
                  <span>{{ selected.public ? "عمومی" : "خصوصی" }}</span>
                </div>
              </v-col>
            </v-row>
            <div class="mb-8">
              <h5 class="dark-grey-color">متن:</h5>
              <p
                v-html="selected.text"
                class="text-justify dark-grey-color"
                style="line-height: 28px"
              ></p>
            </div>

            <v-btn class="blue-mainBtn" @click="edited = true">
              <v-icon class="pe-1">$Pen</v-icon>
              ویرایش</v-btn
            >
            <v-btn
              class="red-borderedBtn float-end"
              @click="templateInfoDialog = false"
              >بازگشت</v-btn
            >
          </v-col>
          <v-col v-else>
            <h5 class="mb-4 dark-grey-color">ویرایش قالب</h5>
            <div class="custom-input">
              <div>
                <v-text-field
                  outlined
                  dense
                  height="40"
                  v-model="selected.name"
                >
                  <template slot="label">
                    <div style="font-size: 14px">عنوان قالب</div>
                  </template>
                </v-text-field>
              </div>
            </div>
            <div class="blue-ckeditor mt-2">
              <div style="font-size: 14px">متن</div>
              <div class="mt-3">
                <vue-ckeditor v-model="selected.text" :config="config" />
              </div>
            </div>

            <div class="switch-div d-flex" v-if="role == 'admin'">
              <div class="v-label theme--light mt-6 me-2">خصوصی</div>
              <v-switch v-model="selected.public" label="عمومی"></v-switch>
            </div>
            <div class="radio-div mt-3">
              <span class="v-label theme--light"
                >کاربرد این قالب در چه نوع نامه‌ای است؟</span
              >
              <v-radio-group v-model="selected.type">
                <v-radio value="outbox">
                  <template slot="label">
                    <div class="mt-2" style="font-size: 14px">خروجی</div>
                  </template>
                </v-radio>
                <v-radio value="internal">
                  <template slot="label">
                    <div class="mt-2" style="font-size: 14px">سازمانی</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <v-btn
              class="blue-mainBtn"
              @click="editTemplate()"
              :disabled="!selected.name || !selected.text || !selected.type"
              >ثبت تغییرات</v-btn
            >
            <v-btn class="red-borderedBtn float-end" @click="edited = false"
              >بازگشت</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="600">
      <v-card class="modal-card">
        <h5 class="dark-grey-color d-flex">
          <v-icon color="error">$Alert</v-icon>
          <h5 class="ps-2 pe-2 pt-1">
            آیا از حذف قالب "{{ deleteName }}" اطمینان دارید؟
          </h5>
        </h5>
        <div class="text-left mt-9">
          <v-btn
            class="blue-mainBtn"
            width="90"
            :loading="deleteBusy"
            @click="deleteTemplate()"
            >بله</v-btn
          >
          <v-btn class="red-borderedBtn ms-2" @click="deleteDialog = false"
            >بازگشت</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Axios from "axios";
import VueCkeditor from "vue-ckeditor2";
import { Circle4 } from "vue-loading-spinner";
export default {
  components: {
    VueCkeditor,
    Circle4,
  },
  data() {
    return {
      config: {
        height: "150px",
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "TextColor",
            "JustifyLeft",
            "JustifyCenter",
            "JustifyRight",
            "Smiley",
            "Font",
            "FontSize",
            "Styles",
          ],
        ],
      },
      search: "",
      showType: "all",
      addTemplateDialog: false,
      templateInfoDialog: false,
      deleteDialog: false,
      isLoaded: false,
      edited: false,
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      templateType: "همه",
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: "عنوان قالب" },
        { key: "public", label: "نوع قالب" },
        { key: "type", label: "کاربرد" },
        { key: "operation", label: "عملیات" },
      ],
      templates: [],
      newTemplate: {},
      selected: {},
      typeOptions: ["همه", "عمومی", "خصوصی"],
      templateId: "",
      deleteId: "",
      deleteName: "",
      role: "",
      addBusy: false,
      deleteBusy: false,
    };
  },
  methods: {
    goToFirst() {
      this.currentPage = 1;
    },
    goToLast() {
      this.currentPage = Math.ceil(this.templates.length / this.perPage);
    },
    getTemplates() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/templateLetters/getAll",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.templates = res.data;
          this.isLoaded = true;
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
    submitTemplate() {
      this.addBusy = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/templateLetter/new",
        {
          name: this.newTemplate.name,
          text: this.newTemplate.text,
          type: this.newTemplate.type,
          public: this.newTemplate.public ? true : false,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.addBusy = false;
          this.toast(res.data, "success");
          this.addTemplateDialog = false;
          this.getTemplates();
        })
        .catch((err) => {
          this.addBusy = false;
          this.toast(err.response.data, "error");
        });
    },
    getTemplateInfo(templateId) {
      this.templateId = templateId;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/templateLetter/getInfo",
        {
          templateId: this.templateId,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.selected = res.data;
          this.templateInfoDialog = true;
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
    openDeleteDialog(item) {
      this.deleteId = item.id;
      this.deleteName = item.name;
      this.deleteDialog = true;
    },
    deleteTemplate() {
      this.deleteBusy = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/templateLetter/delete",
        {
          templateId: this.deleteId,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.deleteBusy = false;
          this.toast("قالب موردنظر حذف شد.", "success");
          this.deleteDialog = false;
          this.getTemplates();
        })
        .catch((err) => {
          this.deleteBusy = false;
          this.toast(err.response.data, "error");
        });
    },
    editTemplate() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/templateLetter/edit",
        {
          templateId: this.templateId,
          name: this.selected.name,
          text: this.selected.text,
          type: this.selected.type,
          public: this.selected.public,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.toast(res.data, "success");
          this.edited = false;
          this.getTemplateInfo(this.templateId);
          this.getTemplates();
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
  },
  computed: {
    computedTemplates() {
      let filtered = [];
      filtered = this.templates;
      if (this.templateType == "عمومی") {
        return filtered.filter(
          (x) => x.public == true && x.name.includes(this.search)
        );
      } else if (this.templateType == "خصوصی") {
        return filtered.filter(
          (x) => x.public == false && x.name.includes(this.search)
        );
      } else {
        filtered = this.templates.filter((x) => x.name.includes(this.search));
        return filtered;
      }
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    this.getTemplates();
  },
};
</script>
<style lang="scss">
* {
  font-family: iransans;
}
@font-face {
  font-family: iransans;
  src: url("../../assets/fonts/IRANSans(FaNum).ttf");
}
.no-template-icon {
  .v-icon__component {
    width: 90px;
    height: 90px;
  }
}

.type-badge {
  background: #e7f2fe;
  border-radius: 20px;
  width: 100px;
  span {
    color: #4c81c9;
  }
}
.letter-templates {
  .template-div {
    background-size: cover;
    box-sizing: border-box;
    height: 350px;
    border-radius: 20px;
  }

  .type-badge {
    background: #e7f2fe;
    border-radius: 20px;
    width: 100px;
    span {
      color: #4c81c9;
    }
  }

  .template-name {
    background-color: rgba(0, 0, 0, 0.51);
    color: #fff;
    border-radius: 0px 0px 20px 20px;
    padding: 15px;
    .v-icon {
      cursor: pointer;
      path {
        stroke: #fff;
      }
    }
  }
}
</style>
